import { TableCell, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../../_metronic/_partials/dropdowns";
import Download from '../Download';
import Remove from '../Remove';
import Rename from '../Rename';

const IconType = (type, is_system) => {
    switch (type) {
        case 'jpg':
        case 'png':
        case 'jpeg':
          return <i className="fas fa-image mr-2 text-primary"></i>;
        case 'pdf':
          return <i className="fas fa-file-pdf mr-2 text-primary"></i>;
        case 'docx':
          return <i className="fas fa-file-word mr-2 text-primary"></i>;
        case 'xlsx':
          return <i className="fas fa-file-excel mr-2 text-primary"></i>;
        case 'txt':
          return <i className="fas fa-file-alt mr-2 text-primary"></i>;
        case 'Folder':
          return is_system ? <i className="fas fa-folder text-danger"></i> : <i className="fas fa-folder text-primary"></i>;
        default:
          return <i className="fas fa-file text-primary"></i>
      }
};

export default function ListRow({list, handleGetContent}){
    const sectionName = list.section_name || list.name;
    const size = list.size ? (Number(list.size)/1024).toFixed(2) + 'MB' : '-';
    const itemType = list.type ? list.type : 'Folder';
    const url = list.temporary_url ? list.temporary_url : null;

    return(
        <TableRow hover={true}>
          <TableCell component="th" scope="row" className="font-weight-bold fs-1 text-capitalize cursor-pointer" onClick={() => handleGetContent(list)}>
                    {list.created_by === 'Default' ? IconType(itemType, true) : IconType(itemType) }
                    {url ?
                      <a href={url} target="_blank" className="text-dark">{sectionName}</a>
                      :
                      <span className="navi-text font-size-lg text-capitalize text-dark ml-2">
                        {sectionName}
                        {sectionName === 'System Folders' && (
                          <Tooltip title="These files are documents the client and team have supplied directly to other pages in the portal. These folders cannot be moved or deleted.">
                            <IconButton size='small'>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </span>
                    }
            </TableCell>
            <TableCell align="left" className="font-weight-bold fs-1">{new Date(list.updated_at).toLocaleDateString('en-CA') === 'Invalid Date' ? '-' : new Date(list.updated_at).toLocaleDateString('en-CA')}</TableCell>
            <TableCell align="left" className="font-weight-bold fs-1">{size}</TableCell>
            <TableCell align="left" className="font-weight-bold fs-1">{itemType}</TableCell>
            <TableCell align="left">
              <div className="btn btn-icon btn-light btn-sm mx-3 svg-icon svg-icon-md svg-icon-primary">
                    <Dropdown className="dropdown-inline" alignRight>
                    <Dropdown.Toggle
                        id="dropdown-toggle-top"
                        as={DropdownCustomToggler}
                    >
                        <i className="ki ki-bold-more-hor" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        <ul className="navi navi-hover">
                          {list.created_by !== 'Default' && (
                            <Rename item={list}/>
                          )}
                          <Download item={list}/>
                          {list.created_by !== 'Default' && (
                            <Remove item={list} />
                          )}
                        </ul>
                    </Dropdown.Menu>
                    </Dropdown>
                </div>
            </TableCell>
        </TableRow>
    );
};

ListRow.defaultProps = {
    list: {
        created_by: 'Default',
        section_name: 'Default Folder',
        section_id: 'Default-Folder',
        size: '-',
        type: 'Folder',
        created_at: new Date().toLocaleDateString('en-CA'),
    }
};