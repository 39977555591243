import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,Button, InputLabel, OutlinedInput, InputAdornment, Label, IconButton } from '@material-ui/core';
import React, { useState } from 'react' 
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Table } from 'react-bootstrap'; 
import ClientWiseAlert from '../../../_metronic/layout/components/customize/Alert';

function ChildSupportArears() {
    const [dates , setData] = useState([]);  
    const [dateModification, setDateModification] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [freeMonths, setFreeMonths] = useState(0)
    const onSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const dateFrom = formData.get('dateFrom');
        const dateTo = formData.get('dateTo');
        const interest = formData.get('interest');
        const amount = formData.get('amount'); 

        const fromDate = new Date(dateFrom);
        const toDate = new Date(dateTo);
        const result = [];

        // Validate if dates are correct and within range
        if (fromDate > toDate) {
            alert("The 'from' date should be earlier than the 'to' date.");
            return;
        }

        let monthIndex = fromDate.getMonth(); // Starting month (0-based)
        let year = fromDate.getFullYear(); // Starting year
        let counter = 1;
        let childSupp = 0;
        let arearInterest = 0;
        // Loop until reaching the end month
        while (year < toDate.getFullYear() || (year === toDate.getFullYear() && monthIndex <= toDate.getMonth())) {
            // Format each month as YYYY-MM-DD
            const formattedDate = `${year}-${String(monthIndex + 1).padStart(2, '0')}-01`;
            childSupp += parseFloat(amount);
            arearInterest += parseFloat(((interest / 365/ 100).toFixed(13) * new Date(year, monthIndex + 1, 0).getDate() * childSupp));

            result.push({
                date: formattedDate,
                amount: amount,
                amountPaid: 0,
                interest: interest,
                daily: (interest / 365).toFixed(4),
                dailyInterest:  ((interest / 365/ 100).toFixed(13) * new Date(year, monthIndex + 1, 0).getDate() * childSupp),
                days: new Date(year, monthIndex + 1, 0).getDate(),
                childSupp: childSupp,
                arearInterest: arearInterest,
            });

            monthIndex += 1;
            counter += 1;

            // Handle year change
            if (monthIndex === 12) {
                monthIndex = 0;
                year += 1;
            }
        }

        setData(result)
    }

    const openAlert = ()=> {
        setShowAlert(true);
  
    }
  
    const closeAlert = ()=> {
        setShowAlert(false);
  
    }

    const onSubmitDateModification = (e) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget); 
        let freeMonthsCounter = 0;
        let isUnpaid = false;

        let newItem = {
            date: formData.get('date'),
            amount: formData.get('amount')
        }

        if (formData.get('date') === '' || formData.get('amount') === '') {
            return
        }
        
        if(dates.length === 0) {
            openAlert()
            return
        }
        var newModifiedItems = dateModification;
        newModifiedItems.push(newItem);
        newModifiedItems.sort((a, b) => new Date(a.date) - new Date(b.date));
        var newCollection = []

        newModifiedItems.forEach((item)=> {
            newCollection = dates.map((value) => {
                if(new Date(value.date) >= new Date(newItem.date))
                {
                    value.amount = newItem.amount 
                }
            
                return value
            })
        })
        
        const updatedDates = newCollection.map((loopObj, i) => {
            // Clone the current row to avoid mutating original state
            const updatedRow = { ...loopObj };
        
            if (i === 0) {
                // Logic for the first row
                updatedRow.amountPaid = updatedRow.amountPaid;
                updatedRow.childSupp = (updatedRow.amount - updatedRow.amountPaid);
                dates[i].amountPaid = updatedRow.amountPaid;
                dates[i].childSupp = (updatedRow.amount - updatedRow.amountPaid);

            if (dates[i].amountPaid < dates[i].amount && freeMonths !== freeMonthsCounter) 
            {
                dates[i].dailyInterest = 0;
                dates[i].arearInterest =  0 ; 
                updatedRow.dailyInterest = 0;
                updatedRow.arearInterest = 0;

                isUnpaid = true;
                freeMonthsCounter++;
            }
            else {
                dates[i].dailyInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * updatedRow.childSupp);
                dates[i].arearInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * updatedRow.childSupp);
                updatedRow.dailyInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * updatedRow.childSupp);
                updatedRow.arearInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * updatedRow.childSupp); 
            }
            } else {
                // Logic for other rows

                dates[i].amountPaid = updatedRow.amountPaid;
                dates[i].childSupp = dates[i - 1].childSupp + (updatedRow.amount - updatedRow.amountPaid);
                updatedRow.amountPaid = updatedRow.amountPaid;
                updatedRow.childSupp = dates[i - 1].childSupp + (updatedRow.amount - updatedRow.amountPaid);
                let index = (i - (freeMonths));

                if (dates[i].amountPaid < dates[i].amount && freeMonths !== freeMonthsCounter) 
                {
                    dates[i].dailyInterest = 0;
                    dates[i].arearInterest =  0 ; 
                    updatedRow.dailyInterest = 0;
                    updatedRow.arearInterest = 0;

                    isUnpaid = true;
                    freeMonthsCounter++;
                } else { 
                    dates[i].dailyInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * dates[i - freeMonths].childSupp);
                    dates[i].arearInterest =  dates[i - 1].arearInterest + dates[i].dailyInterest ;
                    updatedRow.dailyInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * dates[i - freeMonths].childSupp);
                    updatedRow.arearInterest = dates[i - 1].arearInterest + dates[i].dailyInterest ;

                }
            } 

            return updatedRow;
        }); 
        setData(updatedDates);  
    }



    const onAmountPaidChange = (e, index) => {
        let rowObj = dates[index] // Create a copy of the object to avoid direct mutation
        let variable = e.target.value; 
        let freeMonthsCounter = 0;
        let isUnpaid = false;

        rowObj.amountPaid = parseFloat((variable === null || variable === undefined || variable === '') ? 0 : variable);
        
        dates[index] = rowObj  
        
        const updatedDates = dates.map((loopObj, i) => {
            // Clone the current row to avoid mutating original state
            const updatedRow = { ...loopObj };
        
            if (i === 0) {
                // Logic for the first row
                updatedRow.amountPaid = updatedRow.amountPaid;
                updatedRow.childSupp = (updatedRow.amount - updatedRow.amountPaid);
                dates[i].amountPaid = updatedRow.amountPaid;
                dates[i].childSupp = (updatedRow.amount - updatedRow.amountPaid);

            if (dates[i].amountPaid < dates[i].amount && freeMonths !== freeMonthsCounter) 
            {
                dates[i].dailyInterest = 0;
                dates[i].arearInterest =  0 ; 
                updatedRow.dailyInterest = 0;
                updatedRow.arearInterest = 0;

                isUnpaid = true;
                freeMonthsCounter++;
            }
            else {
                dates[i].dailyInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * updatedRow.childSupp);
                dates[i].arearInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * updatedRow.childSupp);
                updatedRow.dailyInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * updatedRow.childSupp);
                updatedRow.arearInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * updatedRow.childSupp); 
            }
            } else {
                // Logic for other rows

                dates[i].amountPaid = updatedRow.amountPaid;
                dates[i].childSupp = dates[i - 1].childSupp + (updatedRow.amount - updatedRow.amountPaid);
                updatedRow.amountPaid = updatedRow.amountPaid;
                updatedRow.childSupp = dates[i - 1].childSupp + (updatedRow.amount - updatedRow.amountPaid);
                let index = (i - (freeMonths));

                if (dates[i].amountPaid < dates[i].amount && freeMonths !== freeMonthsCounter) 
                {
                    dates[i].dailyInterest = 0;
                    dates[i].arearInterest =  0 ; 
                    updatedRow.dailyInterest = 0;
                    updatedRow.arearInterest = 0;

                    isUnpaid = true;
                    freeMonthsCounter++;
                } else { 
                    dates[i].dailyInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * dates[i - freeMonths].childSupp);
                    dates[i].arearInterest =  dates[i - 1].arearInterest + dates[i].dailyInterest ;
                    updatedRow.dailyInterest = ((updatedRow.interest / 365 / 100).toFixed(13) * updatedRow.days * dates[i - freeMonths].childSupp);
                    updatedRow.arearInterest = dates[i - 1].arearInterest + dates[i].dailyInterest ;

                }
            } 

            return updatedRow;
        }); 
        setData(updatedDates); 
    }

  return (
    <div id="childSupportArears" className="row" style={{ gap: '10px'}}> 
    <ClientWiseAlert showAlert={showAlert} closeAlert={closeAlert} message={'Please Load Data first'} status={'warning'}/>
        <div className="col-12">
            <div className="card">
                <div className="card-body"> 
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="timeline">
                            <h3 className="m-0 text-dark font-weight-bolder">Child Support Arrears</h3>
                        </div> 
                        <div className="ml-5"> 
                            
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div className="row col-12">
            <div className="col-6">
                <div className="card">
                    <div className='card-header'>
                        Child Support Starting values
                    </div>
                    <div className="card-body"> 
                        <form onSubmit={onSubmit}>
                            <div className="">  
                                <div className='w-100 m-5'>
                                <label>From</label>
                                    <TextField  
                                        name="dateFrom" 
                                        margin="normal"
                                        variant="outlined"
                                        type="date"
                                        defaultValue={'2010-09-01'}
                                    /> 
                                </div>
                                <div className='w-100 m-5'>
                                    <label>To</label> 
                                    <TextField  
                                        name="dateTo"  
                                        margin="normal"
                                        variant="outlined"
                                        type="date"
                                        defaultValue={'2011-09-16'}
                                    />  
                                </div>
                                <div className='w-100 d-flex'>
                                    <div>
                                        <label className='mx-5'>Amount</label>  
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>} 
                                            name="amount"
                                            className='m-5'
                                            defaultValue={1500}
                                        />  
                                    </div>
                                    <div> 
                                        <label className='mx-5'>Interest</label>   
                                        <OutlinedInput
                                            id="outlined-adornment-interest"
                                            startAdornment={<InputAdornment position="start">%</InputAdornment>} 
                                            name="interest"
                                            className='m-5'
                                            defaultValue={6}
                                        />  
                                    </div> 
                                    <div> 
                                        <label className='mx-5'>Free Months</label>   
                                        <OutlinedInput
                                            id="outlined-adornment-interest" 
                                            name="freeMonths"
                                            className='m-5'
                                            onChange={(e) => setFreeMonths(parseInt(e.target.value))}
                                            value={freeMonths}
                                        />  
                                    </div> 
                                </div> 
                            </div> 
                            <div className='w-100 m-5'>
                                <Button type='submit'>Load</Button>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card">
                    <div className='card-header'>
                        Modifications to Child Support
                    </div>
                    <div className="card-body"> 
                            <div>   
                            <form onSubmit={onSubmitDateModification}>
                                <div className='w-100 m-5 '>  
                                    <OutlinedInput
                                        id="outlined-adornment-interest" 
                                        name="date"
                                        type="date"  
                                    />  
                                    <OutlinedInput
                                        id="outlined-adornment-interest"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>} 
                                        name="amount" 
                                    />   
                                    <IconButton aria-label="add" color="primary" type='submit' >
                                        <AddCircleIcon />
                                    </IconButton>
                                </div> 
                            </form>
                            <div style={{
                                maxHeight: '32rem',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            }}> 
                            {
                                dateModification.map((mod, index) => (
                                    <div className='w-100 m-5 ' key={index}>  
                                        <OutlinedInput
                                            id="outlined-adornment-interest" 
                                            name="date"
                                            type="date"
                                            defaultValue={mod.date}
                                        />  
                                        <OutlinedInput
                                            id="outlined-adornment-interest"
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>} 
                                            name="amount"
                                            defaultValue={mod.amount}
                                        /> 
                                    </div>
                                ))
                            } 
                            </div> 
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        
        <div className="col-12 d-flex">
            <TableContainer component={Paper}>
                <Table aria-label="sticky table">
                <TableHead> 
                    <TableRow> 
                        <TableCell className='purple-cell' colSpan={1}>
                        Date
                        </TableCell> 
                        <TableCell className='orange-cell' colSpan={2}>
                        Child Support
                        </TableCell> 
                        <TableCell className='blue-cell' colSpan={4}>
                        Current Interest End of The Month
                        </TableCell> 
                        <TableCell className='green-cell' colSpan={2}>
                        Arears
                        </TableCell> 
                    </TableRow>
                    <TableRow> 
                        <TableCell className='purple-cell' colSpan={1}>
                            Date
                        </TableCell> 
                        <TableCell className='orange-cell'>
                            Amount Due
                        </TableCell> 
                        <TableCell className='orange-cell'>
                            Ammount Paid
                        </TableCell> 
                        <TableCell className='blue-cell' colSpan={1}>
                            Annual
                        </TableCell> 
                        <TableCell className='blue-cell' colSpan={1}>
                            Daily
                        </TableCell> 
                        <TableCell className='blue-cell' colSpan={1}>
                            Days
                        </TableCell> 
                        <TableCell className='blue-cell'>
                            Interest
                        </TableCell> 
                        <TableCell className='green-cell'>
                            Child Supp
                        </TableCell> 
                        <TableCell className='green-cell'>
                            Interest
                        </TableCell>  
                    </TableRow>
                </TableHead>
                <TableBody> 
                {
                    dates.length > 0 ? 
                    (
                        dates.map((value, index) => (
                            <TableRow key={index}> 
                            <TableCell className='purple-cell' colSpan={1}>
                                {value.date}
                            </TableCell> 
                            <TableCell className='orange-cell'>
                                {value.amount}
                            </TableCell> 
                            <TableCell>
                            <TextField
                                id="outlined-number"
                                label="Ampunt Paid"
                                type="number"
                                fullWidth={true}
                                defaultValue={value.amountPaid}
                                onBlur={(e) => {
                                    onAmountPaidChange(e, index)
                                }}
                                slotprops={{
                                    inputLabel: {
                                    shrink: true,
                                    },
                                }}
                                />
                            </TableCell> 
                            <TableCell className='blue-cell' colSpan={1}>
                                {value.interest}%
                            </TableCell> 
                            <TableCell className='blue-cell' colSpan={1}>
                                {value.daily}%
                            </TableCell> 
                            <TableCell className='blue-cell'  colSpan={1}>
                                {value.days}
                            </TableCell> 
                            <TableCell className='blue-cell'>
                                {value.dailyInterest === 0 ? '-' : value.dailyInterest.toFixed(2)}
                            </TableCell> 
                            <TableCell className='green-cell'>
                                {value.childSupp === 0 ? '-' : value.childSupp.toFixed(2)}
                            </TableCell> 
                            <TableCell className='green-cell'>
                                {value.arearInterest === 0  ? '-' : value.arearInterest.toFixed(2)}
                            </TableCell>  
                        </TableRow>
                        ))
                    )
                    :
                    (
                        
                        <TableRow > 
                            <TableCell colSpan={12}>
                                No Data
                            </TableCell>  
                        </TableRow>
                    )
                } 
                </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div> 
  )
}

export default ChildSupportArears