import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
import { useState } from "react";
import { useFormik } from "formik";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { GetClientCaseId } from "../../../../Functions/General";
import { setClose, setMessage } from "../../../../redux/slices/notifSlice";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export default function DownloadFolder({item}){
    const documentsPage = useSelector(state => state.documentsPage);
    const curFolder = documentsPage.curFolder;
    const client_case_id = GetClientCaseId();
    const dispatch = useDispatch();
    const [downloading, setDownloading] = useState(false);
    const [corruptedFiles, setCorruptedFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
        setCorruptedFiles([]);
    };

    const handleSubmitFolder = (values) => {
        setDownloading(true);
        dispatch(setMessage('DOWNLOADING. This may take a while'));
        formik.setSubmitting(true);
        var formData = {
            section_id: curFolder.source,
            source: curFolder.source,
            source_id: curFolder.source_id || 1,
            client_case_id: client_case_id,
            section_name: curFolder.source,
            parent: curFolder.Parent
        };
        Axios.post(`/api/v2/documents/folder/download/`, formData)
        .then(({data}) => {
            if(data?.corrupted_files?.length > 0){
                setCorruptedFiles(data.corrupted_files);
                setShowModal(true);
            }
            window.open(data.url, '_blank');
        })
        .finally(() => {
            dispatch(setClose());
            setDownloading(false);
        });
    };
    
    const formik = useFormik({
        initialValues:{
            name: ''
        },
        onSubmit: handleSubmitFolder
    });
    
    return(
        <>
        {curFolder?.source && (
            <button className="btn btn-primary mr-3 px-5 py-3 my-3" onClick={formik.handleSubmit}>
                <span className="svg-icon svg-icon-md svg-icon-white">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Downloadedfile.svg")}></SVG>
                </span>
                Download Folder
            </button>
        )}
        <Dialog open={showModal} maxWidth="md" fullWidth>
            <DialogTitle className="font-weight-bolder">Corrupted Files</DialogTitle>
            <DialogContent dividers>
                <div className="d-flex flex-column">
                    <p className="font-weight-bolder">These files we're not downloaded.</p>
                    {corruptedFiles.map((corruptedFile, index) => (
                        <h5 className="m-0 font-weight-bolder">{corruptedFile.name}</h5>
                    ))}
                </div>
            </DialogContent>
            <DialogActions className="justify-content-start p-3">
                <button className="btn btn-danger" onClick={() => handleCloseModal()}>Close</button>
            </DialogActions>
        </Dialog>
        </>
    );
};