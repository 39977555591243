import React, { Suspense, lazy, useContext } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import Basics from "./pages/Basics/Basics";
import CaseTransferForm from "./pages/CaseTransfer/CaseTransferForm";
import UserManagementForm from "./pages/UserManagement/UserManagementForm";
import ClientOverview from "./pages/Basics/ClientOverview";
import AttorneyDashboard from "./pages/AttorneyDashboardV2";
import Lawyer from "./pages/Team/Lawyer";
import ClientActiveList from "./pages/Client/ClientActiveList";
import ClientArchiveList from "./pages/Client/ClientArchiveList";
import ClientProspectiveList from "./pages/Client/ClientProspectiveList";
import UpgradePlan from "./modules/Payment/UpgradePlan";
import { PageIsSubscribed } from "../Functions/General";
import Children from "./pages/Children/Children";
import Budget from "./pages/Budget/Budget";
import Timeline from "./pages/Timeline/Timeline";
import Assistant from "./pages/Team/Assistant";
import Estate from "./pages/Estate/Estate";
import EstateDivision from "./pages/Estate/EstateDivision";
import EstateDefault from "./pages/Estate/EstateDefault";
import NotesPage from "./pages/Notes/NotesPage";
import ActivityLogs from "./pages/ActivityLogs/ActivityLogs";
import { EncryptStorage } from "encrypt-storage";
import SubscriptionSettings from "./modules/UserProfile/Subscription/SubscriptionSettings";
import IdleComponent from "./pages/IdleComponent";
import { useSelector } from "react-redux";
import SendInBlueContacts from "./pages/AdminDashboard/SendInBlueContacts";
import Admin from "./pages/AdminPage/Admin";
import SystemAdministration from "./pages/SystemAdministration/SystemAdministration";
import { SnackBarOptionsContext } from "../hooks/ContextStore";
import { Snackbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useLocation  } from "react-router-dom";
import { useEffect } from "react";
import DocumentsPage from "./pages/DocumentsV2/DocumentsPage";
import ExpenseTracker from './pages/ExpenseTracker/index';
import ExpenseDivider from './pages/ExpenseDivider/index'
import ChildSupportArears from "./pages/ChildSupportArears";
import Axios from "axios";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const encryptStorage = new EncryptStorage("secret-key");
  const { user } = useSelector((state) => state.auth);
  const [snackbarOptions, setSnackbarOptions] = useContext(SnackBarOptionsContext);
  const location = useLocation();
  const history = useHistory();
  const role = user ? user.data.roles[0].name : null;

  const handleFetchChat = async () => {
    try {
      await Axios.get(`${process.env.REACT_APP_CHAT_API}/live-chat/?userId=${user.data.id}`)
      .then((res) => {
        const script = document.createElement('script');
        script.innerHTML = res.data;
        document.head.appendChild(script);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleFetchChat();
  }, []);

  useEffect(() => {
    if (user && location.pathname === "/") {
      history.push("/attorney");
    }
  }, [user, location.pathname, history]);

  return (
    <>
      <IdleComponent />
      <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute
          exact
          path="/"
          component={PageIsSubscribed() ? AttorneyDashboard : SubscriptionSettings}
        />
        <ContentRoute path="/activity/logs/" component={ActivityLogs} />
        <ContentRoute path="/basics" component={Basics} />
        <ContentRoute path="/budget/" component={Budget} />
        <ContentRoute path="/user-management" component={UserManagementForm} />
        <ContentRoute path="/case-transfer" component={CaseTransferForm} />
        <ContentRoute path="/attorney" component={PageIsSubscribed() ? AttorneyDashboard : SubscriptionSettings} />
        <ContentRoute path="/subscription" component={SubscriptionSettings} />
        <ContentRoute path="/lawyer" component={Lawyer} />
        <ContentRoute path="/Assistant" component={Assistant} />
        <ContentRoute path="/client/active" component={ClientActiveList} />
        <ContentRoute path="/admin/haystak5njsmn2hqkewecpaxetahtwhsbsa64jom2k22z5afx" component={Admin} />
        <ContentRoute path="/client/archive" component={ClientArchiveList} />
        <ContentRoute path="/client/prospective" component={ClientProspectiveList} />
        <ContentRoute path="/client-overview" component={ClientOverview} />
        <ContentRoute path="/timeline" component={Timeline} />
        <ContentRoute path="/estate" component={Estate} />
        <ContentRoute path="/estate-division" component={EstateDivision} />
        <ContentRoute path="/estates/default" component={EstateDefault} />
        <ContentRoute path="/documents" component={DocumentsPage} />
        <ContentRoute path="/documentsv2" component={DocumentsPage} />
        <ContentRoute path="/expense-tracker" component={ExpenseTracker} />
        <ContentRoute path="/expense-divider" component={ExpenseDivider} />
        <ContentRoute path="/childsupport-arears" component={ChildSupportArears} />
        <ContentRoute path="/notes" component={NotesPage} />
        <ContentRoute path="/children" component={Children} />
        <ContentRoute path="/general/send-in-blue/contacts" component={SendInBlueContacts} />
        <ContentRoute path="/upgrade-subscription" component={UpgradePlan} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/system" component={SystemAdministration} />
        <Redirect exact from="/auth/mfa" to={role === "client" ? "/" : "/attorney"} />  {/* remove this if things go wrong */}
        <Redirect exact from="/auth/login" to={role === "client" ? "/" : "/attorney"} />      {/* remove this if things go wrong */}
        <Redirect to="../error/error-v3" />
      </Switch>
      </Suspense>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOptions.show}
        autoHideDuration={3000}
       // onClose={handleCloseNotif}
        message={snackbarOptions.title}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOptions({show: false})}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}
