import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDownloadDocuments } from '../../../../redux/slices/documentsPageSlice';
import Axios from 'axios';
import { GetClientCaseId } from '../../../../Functions/General';
import { Dialog, DialogTitle, DialogContent, DialogActions} from "@material-ui/core";
export default function DownloadAll(){
    const client_case_id = GetClientCaseId();
    const { download_documents } = useSelector(state => state.documentsPage)
    const [showModal, setShowModal] = useState(false);
    const [corruptedFiles, setCorruptedFiles] = useState([]);
    const dispatch = useDispatch();

    const handleDownload = async () => {
        dispatch(setDownloadDocuments(true));
        await Axios.get(`/api/v2/documents/folder/download-all/${client_case_id}`)
        .then(({data}) => {
            if(data?.corrupted_files?.length > 0){
                setCorruptedFiles(data.corrupted_files);
                setShowModal(true);
            }
            window.open(data.url, '_blank');
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCorruptedFiles([]);
    };

    return(
        <>
        <button className='mr-1 btn btn-primary' onClick={() => handleDownload()} disabled={download_documents}>
            Download All
            {download_documents && <span className="ml-1 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
        </button>
        <Dialog open={showModal} maxWidth="md" fullWidth>
            <DialogTitle className="font-weight-bolder">Corrupted Files</DialogTitle>
            <DialogContent dividers>
                <div className="d-flex flex-column">
                    <p className="font-weight-bolder">These files we're not downloaded.</p>
                    {corruptedFiles.map((corruptedFile, index) => (
                        <h5 className="m-0 font-weight-bolder">{corruptedFile.name}</h5>
                    ))}
                </div>
            </DialogContent>
            <DialogActions className="justify-content-start p-3">
                <button className="btn btn-danger" onClick={() => handleCloseModal()}>Close</button>
            </DialogActions>
        </Dialog>
        </>
    );
};